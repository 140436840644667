import React, { useState } from 'react';
import styles from './styles.module.css';
import { WrapImage } from '../wrapImage';
import ListJobChoose from '../ListJobChoose';
import { SearchButton } from '../searchButton';
import { useRouter } from 'next/router';
import { T } from '../../hooks/translation';
import Dialog from '@mui/material/Dialog';
type SearchListMobileProps = {
  setShowSearchListMobile: any;
  init_data: any;
  setParamsFilter: any;
  filterSearch: any;
  showSearchListMobile: any;
};
export default function SearchListMobile({
  setShowSearchListMobile,
  init_data,
  setParamsFilter,
  filterSearch,
  showSearchListMobile,
}: SearchListMobileProps) {
  const [showButton, setShowButton] = useState<number>(0);
  const router = useRouter();
  const { dept, location, type } = router.query;
  return (
    <Dialog open={showSearchListMobile} fullScreen={true}>
      <div className={styles.searchListMobile}>
        <div className={styles.searchListMobile_header}>
          <div
            className={styles.searchListMobile_headerButton}
            onClick={() => setShowSearchListMobile(false)}
          >
            {T('dong')}
          </div>
          <div className={styles.searchListMobile_headding}>{T('bo-loc')}</div>
          <div
            className={styles.searchListMobile_headerButton}
            onClick={() => {
              const checkedList = document.querySelectorAll(
                'input[type=checkbox]'
              );
              checkedList.forEach((checbox) => {
                if ((checbox as HTMLInputElement | null)?.checked) {
                  (checbox as HTMLInputElement | null)?.click();
                }
              });
              router.push(`/`, undefined, { scroll: false });
            }}
          >
            <WrapImage
              desktop={{
                src: '/images/delete.png',
                alt: 'Facebook',
                layout: 'fill',
                className: `${styles.deleteIcon}`,
              }}
            />
            {T('dat-lai')}
          </div>
        </div>
        <div className={styles.searchListMobile_body}>
          <ListJobChoose
            paramsFilter={null}
            init_data={init_data}
            setShowButton={setShowButton}
            setParamsFilter={setParamsFilter}
          />
        </div>
        <div
          onClick={() => {
            filterSearch();
            setShowSearchListMobile(false);
          }}
        >
          <SearchButton
            className={
              showButton || dept || location || type
                ? styles.searchListMobile_buttonSearch + ' ' + styles.show
                : styles.searchListMobile_buttonSearch
            }
          >
            <span>
              {T('ap-dung-bo-loc')}
              {showButton ? ` (${showButton})` : ``}
            </span>
          </SearchButton>
        </div>
      </div>
    </Dialog>
  );
}
