import React, { useState } from 'react';
import styles from './styles.module.css';
import { WrapImage } from '../wrapImage';
import ListJobChoose from '../ListJobChoose';
import { shareFacebook, shareEmail, shareLinkedIn } from '../../utils';
import { useRouter } from 'next/router';
import { StoreContext } from '../../utils/store';
// import { useWindowWidth } from '../../hooks/useWindowWidthHook';
type SearchListProps = {
  init_data: any;
  setParamsFilter: any;
  filterSearch: any;
  loading: boolean;
  paramsFilter: any;
};
export default function SearchList({
  init_data,
  setParamsFilter,
  paramsFilter,
}: SearchListProps) {
  const [showButton, setShowButton] = useState<number>(0);
  const router = useRouter();
  const { trans }: any = React.useContext(StoreContext);

  return (
    <div className={styles.searchListBox}>
      <div className={styles.searchList}>
        <div className={styles.searchList_head}>
          <h2 className={styles.searchList_headding}>{trans('bo-loc')}</h2>
          {showButton ? (
            <div
              className={styles.searchList_reset}
              onClick={() => {
                const checkedList = document.querySelectorAll(
                  'input[type=checkbox]'
                );
                checkedList.forEach((checbox) => {
                  if ((checbox as HTMLInputElement | null)?.checked) {
                    (checbox as HTMLInputElement | null)?.click();
                  }
                });
                router.push(
                  `?${[
                    paramsFilter.keySearch
                      ? 'name=' + paramsFilter.keySearch
                      : '',
                  ]
                    .filter((e) => e != '')
                    .join('&')}`,
                  undefined,
                  { scroll: false }
                );
              }}
            >
              <WrapImage
                desktop={{
                  src: '/images/delete.png',
                  alt: 'Instagram',
                  layout: 'fill',
                  className: `${styles.deleteIcon}`,
                }}
              />
              {trans('dat-lai')}
            </div>
          ) : null}
        </div>
        <ListJobChoose
          setShowButton={setShowButton}
          init_data={init_data}
          setParamsFilter={setParamsFilter}
          paramsFilter={paramsFilter}
        />
      </div>
      <ShareFeature />
      <div style={{ flex: 1 }}></div>
    </div>
  );
}
export function ShareFeature() {
  const { trans }: any = React.useContext(StoreContext);
  // const width = useWindowWidth();
  const shareToFaceBook = () => {
    shareFacebook(location.href);
  };

  const shareToLinkedIn = () => {
    shareLinkedIn(location.href);
  };

  const shareToEmail = () => {
    shareEmail(location.href);
  };
  // const [showFbPlugin, setShowFbPlugin] = useState(false);
  // useEffect(() => {
  //   setShowFbPlugin(true);
  // }, []);
  return (
    <div
      style={
        // width <= 768
        //   ? {
        //       height: `${96 + 48 + ((width - 62) / 314) * 130}px`,
        //       paddingBottom: `${(((width - 62) / 314) * 130 - 130) / 2 + 24}px`,
        //     }
        //   : {}
        {}
      }
      className={styles.searchList + ' ' + styles.shareBox}
    >
      <div style={{ width: '100%' }}>
        <div className={styles.searchList_headding + ' ' + styles.share}>
          {trans('chia-se')}
        </div>
        <div className={styles.share_iconList}>
          <span className={styles.shareSocialBox} onClick={shareToFaceBook}>
            <WrapImage
              desktop={{
                src: '/images/facebook.svg',
                alt: 'Facebook',
                layout: 'fill',
                className: `${styles.socialImage}`,
              }}
            />
          </span>
          <span className={styles.shareSocialBox} onClick={shareToLinkedIn}>
            <WrapImage
              desktop={{
                src: '/images/instagram.svg',
                alt: 'Instagram',
                layout: 'fill',
                className: `${styles.socialImage}`,
              }}
            />
          </span>
          <div className={styles.shareSocialBox} onClick={shareToEmail}>
            <WrapImage
              desktop={{
                src: '/images/google.svg',
                alt: 'Google',
                layout: 'fill',
                className: `${styles.socialImage}`,
              }}
            />
          </div>
        </div>
      </div>
      <a
        href='https://www.facebook.com/telio.careers'
        target='_blank'
        rel='noopener noreferrer'
        style={{ width: '100%', position: 'relative' }}
      >
        <WrapImage
          desktop={{
            src: '/images/shareImage.png',
            alt: 'union',
            layout: 'fill',
            objectFit: 'cover',
            className: `${styles.shareImage}`,
          }}
        />
        <div className={styles.share_pageFacebook}>
          <WrapImage
            desktop={{
              src: '/images/FaceBookBlue.png',
              alt: 'union',
              layout: 'fill',
              objectFit: 'cover',
              className: `${styles.shareImage_facebookBlue}`,
            }}
          />
          <div className={styles.share_pageFacebook_describes}>
            <h2 className={styles.share_pageFacebook_name}>Telio Careers</h2>
            <div className={styles.share_pageFacebook_follow}>5K like</div>
          </div>
        </div>
      </a>
      {/* 
      <iframe
        style={
          width > 768
            ? { borderRadius: '12px', overflow: 'hidden', width: '100%' }
            : {
                borderRadius: '12px',
                overflow: 'hidden',
                width: `${width < 375 ? '100%' : '283px'}`,
                transform: `${
                  width >= 375 ? `scale(${(width - 62) / 314})` : ``
                }`,
                background: 'gray',
              }
        }
        src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftelio.careers&tabs=banner&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=334785925377714'
        width={314}
        scrolling='no'
        frameBorder={0}
        allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
      >
      </iframe> */}
      {/* {showFbPlugin && (
        <iframe
          style={
            width > 768
              ? { border: 'none', borderRadius: '12px', overflow: 'hidden' }
              : {
                  border: 'none',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  width: `${width < 375 ? '100%' : '283px'}`,
                  transform: `${
                    width >= 375 ? `scale(${(width - 90) / 283})` : ``
                  }`,
                  background: 'gray',
                }
          }
          src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftelio.careers&tabs=banner&width=283&height=146&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=334785925377714'
          width={width < 375 ? (width / 375) * 283 : 283}
          height={width < 375 ? (width / 375) * 130 : 130}
          scrolling='no'
          frameBorder={0}
          allowFullScreen={true}
          allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
        />
      )} */}
    </div>
  );
}
