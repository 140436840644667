import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { WrapImage } from '../wrapImage';
import { T } from '../../hooks/translation';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { useRouter } from 'next/router';
import { useWindowWidth } from '../../hooks/useWindowWidthHook';
type ListJobChooseProps = {
  init_data: any;
  setShowButton: any;
  setParamsFilter: any;
  paramsFilter: any;
};
export default function ListJobChoose({
  init_data,
  setShowButton,
  setParamsFilter,
}: ListJobChooseProps) {
  const [listdepts, setlistdepts] = useState<string[]>([]);
  const [listOffices, setlistOffices] = useState<string[]>([]);
  const [jobType, setjobType] = useState<string[]>([]);
  const [deptSearchKey, setDeptSearchKey] = useState('');
  const [officeSearchKey, setOfficeSearchKey] = useState('');
  const width = useWindowWidth();
  const changeCharacters = useCallback((str: string) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    return str;
  }, []);

  const router = useRouter();
  const { locale = 'vi' } = router;
  const {
    dept = '',
    location = '',
    type = '',
    name = '',
    ...otherQuery
  } = router.query;
  useEffect(() => {
    setlistdepts(dept !== '' ? (dept as string).split(',') : []);
    setlistOffices(location !== '' ? (location as string).split(',') : []);
    setjobType(type !== '' ? (type as string).split(',') : []);
  }, [dept, location, type]);
  const convertQueryObject = useCallback((queryObject: any) => {
    return Object.keys(queryObject).map((key) => {
      return `${key}=${queryObject[key]}`;
    });
  }, []);
  useEffect(() => {
    // show trigger button if there is a check box selected
    setShowButton(listOffices.length + listdepts.length + jobType.length);
    setParamsFilter((paramsFilter: any) => {
      return {
        ...paramsFilter,
        dept: listdepts.join(),
        location: listOffices.join(),
        type: jobType.join(),
      };
    });
    if (width > 768) {
      const searchQueryArray = [
        listdepts.join() ? 'dept=' + listdepts.join() : '',
        listOffices.join() ? 'location=' + listOffices.join() : '',
        jobType.join() ? 'type=' + jobType.join() : '',
        name ? 'name=' + name : '',
      ].filter((e) => e != '');
      const fullQueryArray = [
        ...convertQueryObject(otherQuery),
        ...searchQueryArray,
      ];
      const fullQueryArrayString =
        fullQueryArray.length > 0 ? `?${fullQueryArray.join('&')}` : '';
      router.push(fullQueryArrayString, undefined, { scroll: false });
    }
  }, [listOffices, listdepts, jobType, name, width]);
  return (
    <>
      <MuiAccordion
        disableGutters
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className={styles.Accordion}
        defaultExpanded={true}
      >
        <AccordionSummary
          className={styles.AccordionSummary}
          expandIcon={
            <WrapImage
              desktop={{
                src: '/images/UnionDown.svg',
                alt: 'union',
                width: 18,
                height: 9,
                className: `${styles.downIcon}`,
              }}
            />
          }
        >
          <div className={styles.searchList_categoryHeadding}>
            <div className={styles.searchList_categoryName}>
              {T('phong-ban')}
              {listdepts.length ? (
                <div className={styles.chooseNumber}>
                  <span className={styles.numberCount}>{listdepts.length}</span>
                </div>
              ) : null}
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className={styles.AccordionDetails}>
          <div className={styles.searchList_category}>
            {/* list dept check box */}

            <div className={styles.searchList_categoryList_inputBox}>
              <input
                placeholder={T('tim-phong-ban')}
                className={
                  styles.searchList_categoryList_input + ' ' + styles.show
                }
                value={deptSearchKey}
                onChange={function (e) {
                  setDeptSearchKey(e.target.value);
                }}
              />
              <WrapImage
                desktop={{
                  src: '/images/search.png',
                  alt: 'search',
                  layout: 'fill',
                  objectFit: 'contain',
                  className: `${styles.searchIcon + ' ' + styles.show}`,
                }}
              />
              {deptSearchKey ? (
                <div
                  className={styles.deleteKeySearchButtonBox}
                  onClick={() => setDeptSearchKey('')}
                >
                  <WrapImage
                    desktop={{
                      src: '/images/exitBox.png',
                      alt: 'delete',
                      width: 18,
                      height: 18,
                      className: `${
                        styles.deleteKeySearchButton + ' ' + styles.show
                      }`,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.searchList_categoryList}>
              {(
                init_data?.listdepts.filter((dept: any) =>
                  changeCharacters(dept?.name)
                    .toLowerCase()
                    .includes(
                      changeCharacters(deptSearchKey).toLocaleLowerCase()
                    )
                ) || []
              ).map((depts: any, index: number) => (
                <div
                  key={index}
                  className={styles.searchList_key + ' ' + styles.show}
                >
                  <input
                    className={styles.searchList_keyCheckbox}
                    type='checkbox'
                    id='checkbox'
                    value={depts?.ref_id}
                    onChange={function (e) {
                      if (e.target.checked) {
                        setlistdepts((listdepts) =>
                          listdepts
                            .filter((dept) => dept != e.target.value)
                            .concat([e.target.value])
                        );
                      } else {
                        setlistdepts((listdepts) =>
                          listdepts.filter((dept) => dept != e.target.value)
                        );
                      }
                    }}
                    name='checkbox'
                    checked={listdepts.includes(depts?.ref_id)}
                  />
                  <span className={styles.searchList_keyValue}>
                    {depts?.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </MuiAccordion>

      <MuiAccordion
        disableGutters
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className={styles.Accordion}
        defaultExpanded={true}
      >
        <AccordionSummary
          className={styles.AccordionSummary}
          expandIcon={
            <WrapImage
              desktop={{
                src: '/images/UnionDown.svg',
                alt: 'union',
                width: 18,
                height: 9,
                className: `${styles.downIcon}`,
              }}
            />
          }
        >
          <div className={styles.searchList_categoryHeadding}>
            <div className={styles.searchList_categoryName}>
              {T('dia-diem')}
              {listOffices.length ? (
                <div className={styles.chooseNumber}>
                  <span className={styles.numberCount}>
                    {listOffices.length}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className={styles.AccordionDetails}>
          <div className={styles.searchList_category}>
            <div className={styles.searchList_categoryList_inputBox}>
              <input
                className={
                  styles.searchList_categoryList_input + ' ' + styles.show
                }
                value={officeSearchKey}
                onChange={function (e) {
                  setOfficeSearchKey(e.target.value);
                }}
                placeholder={T('tim-dia-diem')}
              />
              <WrapImage
                desktop={{
                  src: '/images/search.png',
                  alt: 'search',
                  layout: 'fill',
                  objectFit: 'contain',
                  className: `${styles.searchIcon + ' ' + styles.show}`,
                }}
              />
              {officeSearchKey ? (
                <div
                  className={styles.deleteKeySearchButtonBox}
                  onClick={() => setOfficeSearchKey('')}
                >
                  <WrapImage
                    desktop={{
                      src: '/images/exitBox.png',
                      alt: 'delete',
                      width: 18,
                      height: 18,
                      className: `${
                        styles.deleteKeySearchButton + ' ' + styles.show
                      }`,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* list office checkbox */}
            <div className={styles.searchList_categoryList}>
              {(
                init_data?.listOffices.filter((office: any) =>
                  changeCharacters(office?.name)
                    .toLowerCase()
                    .includes(
                      changeCharacters(officeSearchKey).toLocaleLowerCase()
                    )
                ) || []
              ).map((office: any, index: number) => (
                <div
                  key={index}
                  className={styles.searchList_key + ' ' + styles.show}
                >
                  <input
                    className={styles.searchList_keyCheckbox}
                    type='checkbox'
                    id='checkbox'
                    value={office?.ref_id}
                    name='checkbox'
                    checked={listOffices.includes(office?.ref_id)}
                    onChange={function (e) {
                      if (e.target.checked) {
                        setlistOffices((listOffices) =>
                          listOffices
                            .filter((office) => office != e.target.value)
                            .concat([e.target.value])
                        );
                      } else {
                        setlistOffices((listOffices) =>
                          listOffices.filter(
                            (office) => office != e.target.value
                          )
                        );
                      }
                    }}
                  />
                  <span className={styles.searchList_keyValue}>
                    {office?.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </MuiAccordion>

      <MuiAccordion
        disableGutters
        style={{ marginBottom: '16px' }}
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className={styles.Accordion}
        defaultExpanded={true}
      >
        <AccordionSummary
          style={{ marginBottom: '8px' }}
          className={styles.AccordionSummary}
          expandIcon={
            <WrapImage
              desktop={{
                src: '/images/UnionDown.svg',
                alt: 'union',
                width: 18,
                height: 9,
                className: `${styles.downIcon}`,
              }}
            />
          }
        >
          <div className={styles.searchList_categoryHeadding}>
            <div className={styles.searchList_categoryName}>
              {T('loai-cong-viec')}
              {jobType.length ? (
                <div className={styles.chooseNumber}>
                  <span className={styles.numberCount}>{jobType.length}</span>
                </div>
              ) : null}
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className={styles.AccordionDetails}>
          <div className={styles.searchList_category}>
            <div
              className={styles.searchList_categoryList_inputBox}
              style={{ marginBottom: '-6px' }}
            ></div>
            {/* list job type checkbox */}
            <div className={styles.searchList_categoryList}>
              {(init_data?.jobType || []).map((job: any, index: number) => (
                <div
                  key={index}
                  className={styles.searchList_key + ' ' + styles.show}
                >
                  <input
                    className={styles.searchList_keyCheckbox}
                    type='checkbox'
                    id='checkbox'
                    value={job?.value}
                    checked={jobType.includes(job?.value)}
                    onChange={function (e) {
                      if (e.target.checked) {
                        setjobType((jobType) =>
                          jobType
                            .filter((type) => type != e.target.value)
                            .concat([e.target.value])
                        );
                      } else {
                        setjobType((jobType) =>
                          jobType.filter((type) => type != e.target.value)
                        );
                      }
                    }}
                    name='checbox'
                  />
                  <span className={styles.searchList_keyValue}>
                    {job?.name[locale]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </MuiAccordion>
    </>
  );
}
