import styles from './styles.module.css';
import React from 'react';
import { WrapImage } from '../wrapImage';
import { ClickButton } from '../ClickButton';
import { T } from '../../hooks/translation';
import { useRouter } from 'next/router';
type JobProps = {
  jobDetail: any;
};
export default function Job({ jobDetail }: JobProps) {
  const router = useRouter();
  const { locale = 'vi' } = router;
  return (
    <div
      className={styles.jobBox}
      onClick={() => {
        router.push(`/job/${jobDetail?.slug}`);
      }}
    >
      <div className={styles.job}>
        <div className={styles.jobStyleBox}>
          <div className={styles.jobStyle}>
            {(jobDetail?.dept?.name ? jobDetail?.dept?.name + ' | ' : '') +
              (jobDetail?.job_type?.name?.[locale] ||
                jobDetail?.job_type?.type)}
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        <h2 className={styles.jobDescribes}>{jobDetail?.name}</h2>
        <div className={styles.describes}>
          <WrapImage
            desktop={{
              src: '/images/Salary.png',
              alt: 'union',
              layout: 'fill',
              objectFit: 'contain',
              className: `${styles.icon}`,
            }}
          />
          <div
            className={styles.describes_text}
            dangerouslySetInnerHTML={{
              __html:
                jobDetail?.salary?.min < 1000000
                  ? T('thoa-thuan')
                  : new Intl.NumberFormat('de-DE').format(
                      jobDetail?.salary?.min
                    ) +
                    ' - ' +
                    new Intl.NumberFormat('de-DE').format(
                      jobDetail?.salary?.max
                    ) +
                    ' VNĐ',
            }}
          ></div>
        </div>
        <div className={styles.describes}>
          <WrapImage
            desktop={{
              src: '/images/address.png',
              alt: 'union',
              layout: 'fill',
              objectFit: 'contain',
              className: `${styles.icon}`,
            }}
          />
          <div className={styles.describes_text}>
            {/* render list job */}
            {jobDetail.offices
              .map((office: { id: string; name: string }) => office?.name)
              .join(', ')}
          </div>
        </div>
        <div className={styles.describes}>
          <WrapImage
            desktop={{
              src: '/images/Time.png',
              alt: 'union',
              layout: 'fill',
              objectFit: 'contain',
              className: `${styles.icon}`,
            }}
          />
          <div
            className={styles.describes_text}
            dangerouslySetInnerHTML={{ __html: jobDetail?.display?.period }}
          ></div>
        </div>
        <div className={styles.jobStyle_buttonBox}>
          <ClickButton className={styles.jobStyle_button}>
            <span>{T('ung-tuyen-ngay')}</span>
          </ClickButton>
        </div>
      </div>
    </div>
  );
}
