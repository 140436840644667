import React from 'react';
import { T } from '../../hooks/translation';
import { WrapImage } from '../wrapImage';
import styles from './styles.module.css';

export const AboutUs = () => {
  return (
    <div className={styles.aboutMeBox}>
      <div className={styles.headdingBox}>
        <h2 className={styles.headding}>{T('about-us')}</h2>
      </div>
      <div className={styles.listCard}>
        <div className={styles.cardBox}>
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://cafebiz.vn/ceo-startup-telio-hanh-trinh-goi-von-con-nguoi-la-gian-nan-nhat-20220325181255757.chn'
          >
            <div className={styles.card}>
              <WrapImage
                desktop={{
                  src: '/images/unsplash_C3V88BOoRoM.png',
                  alt: 'union',
                  layout: 'fill',
                  className: `${styles.image}`,
                }}
              />
              <h4 className={styles.cardHeadding}>CAFEBIZ.VN</h4>
              <p className={styles.cardContent}>
                CEO startup Telio: Hành trình &quot;gọi vốn con người&quot; là
                gian nan nhất
              </p>
            </div>
          </a>
        </div>
        <div className={styles.cardBox}>
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://nhipcaudautu.vn/kinh-doanh/ceo-telio-tang-cuong-tim-nhan-tai-mo-rong-tam-anh-huong-3344510'
          >
            <div className={styles.card}>
              <WrapImage
                desktop={{
                  src: '/images/abou2.png',
                  alt: 'union',
                  layout: 'fill',
                  className: `${styles.image}`,
                }}
              />
              <h4 className={styles.cardHeadding}>NHIPCAUDAUTU.VN</h4>
              <p className={styles.cardContent}>
                CEO Telio tăng cường tìm nhân tài, mở rộng tầm ảnh hưởng
              </p>
            </div>
          </a>
        </div>
        <div className={styles.cardBox}>
          <a
            target={'_blank'}
            rel='noreferrer'
            href='https://thesaigontimes.vn/chinh-thuc-tro-thanh-nen-tang-b2b-hang-dau-viet-nam-telio-goi-von-chien-luoc-tu-vng-ggv-capital-va-tiger-global/'
          >
            <div className={styles.card}>
              <WrapImage
                desktop={{
                  src: '/images/about3.png',
                  alt: 'union',
                  layout: 'fill',
                  className: `${styles.image}`,
                }}
              />
              <h4 className={styles.cardHeadding}>THESAIGONTIMES.VN</h4>
              <p className={styles.cardContent}>
                Chính thức trở thành nền tảng B2B hàng đầu Việt Nam, Telio gọi
                vốn chiến lược từ VNG, GGV Capital và Tiger Global
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
