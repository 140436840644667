import { useRouter } from 'next/router';
import * as React from 'react';
import FixedHeaderMobile from '../../components/fixedHeaderMobile';
import Job from '../../components/Job';
import Reason from '../../components/Reason';
import SearchList, { ShareFeature } from '../../components/searchList';
import SearchListMobile from '../../components/searchListMobile';
import { WrapImage } from '../../components/wrapImage';
import { useWindowWidth } from '../../hooks/useWindowWidthHook';
import dynamic from 'next/dynamic';
import styles from './styles.module.css';
import { T } from '../../hooks/translation';
import { AboutUs } from '../../components/AboutUs';
import smoothscroll from 'smoothscroll-polyfill';
import { StoreContext } from '../../utils/store';
import Header from '../../components/header';

// Create some dynamic component
const DynamicFooterBanner = dynamic(
  () => import('../../components/footerBanner'),
  { ssr: false }
);
const DynamicFooter = dynamic(() => import('../../components/footer'));
const DynamicSearchFeature = dynamic(
  () => import('../../components/SearchFeature'),
  { ssr: false }
);
// Define component props type
type ListJobContainerProps = {
  listJob: any[];
  init_data: any;
  isLoading: any;
};
//Define state to get prams for filter search
type pramsFilterType = {
  dept: string;
  location: string;
  type: string;
  keySearch: string;
};

function ListJobContainer({
  listJob = [],
  init_data,
  isLoading,
}: ListJobContainerProps) {
  const width = useWindowWidth();
  const router = useRouter();
  const { name = '' } = router.query;
  const [paramsFilter, setParamsFilter] = React.useState<pramsFilterType>({
    dept: '',
    location: '',
    type: '',
    keySearch: '',
  });
  const jobRef = React.useRef(null);
  const [showSearchListMobile, setShowSearchListMobile] = React.useState(false);
  // Search list job with one or more params
  const filterSearch = async (checkDeleted: boolean = false) => {
    router.push(
      `?${[
        paramsFilter.dept ? 'dept=' + paramsFilter.dept : '',
        paramsFilter.location ? 'location=' + paramsFilter.location : '',
        paramsFilter.type ? 'type=' + paramsFilter.type : '',
        paramsFilter.keySearch && !checkDeleted
          ? 'name=' + paramsFilter.keySearch
          : '',
      ]
        .filter((e) => e != '')
        .join('&')}`,
      undefined,
      { scroll: false }
    );
  };
  const { navTo }: any = React.useContext(StoreContext);
  const scrollHandle = React.useCallback(() => {
    if (navTo[0] === 'open-position' && (jobRef?.current as any)?.offsetTop) {
      smoothscroll.polyfill();
      // console.log('use', (jobRef?.current as any)?.offsetTop - 100);
      window.scrollTo({
        left: 0,
        top: (jobRef?.current as any)?.offsetTop - 100,
        behavior: 'smooth',
      });
      navTo[1]('');
    }
  }, [navTo]);
  React.useEffect(() => {
    scrollHandle();
    setParamsFilter((paramsFilter) => ({
      ...paramsFilter,
      keySearch: name as string,
    }));
  }, []);
  return (
    <div className={styles.wrapBlogContainer}>
      <Header />
      <FixedHeaderMobile />

      <div className={styles.listJobPage}>
        <div className={styles.bannerBackground}>
          <WrapImage
            desktop={{
              src: '/images/bannerJobBackground.png',
              alt: 'banner-background logo',
              layout: 'fill',
              objectFit: 'cover',
              style: {
                width: '100%',
                height: '498px',
                position: 'relative',
                display: 'block',
              },
              priority: true,
            }}
            mobile={{
              src: '/images/bannerJobBackgroundMobile.png',
              alt: 'banner-background logo',
              layout: 'fill',
              objectFit: 'cover',
              style: {
                width: '100%',
                height: '307px',
                position: 'relative',
                display: 'block',
              },
              priority: true,
            }}
          />
          <div className={styles.bannerButtonBox}>
            <h2 className={styles.banner_headding}>
              {T('gia-nhap-cung-telio-ngay-hom-nay')}
            </h2>
            <button
              className={styles.banner_button}
              onClick={() => {
                // console.log('click', (jobRef?.current as any)?.offsetTop - 100);
                window.scrollTo({
                  left: 0,
                  top: (jobRef?.current as any)?.offsetTop - 100,
                  behavior: 'smooth',
                });
              }}
            >
              {T('ung-tuyen-ngay')}
            </button>
          </div>
        </div>
        <div className={styles.listImageBox_headdingBox}>
          <h2 className={styles.listImageBox_headding}>
            {T('tai-telio-chung-toi-cung-phat-trien')}
          </h2>
        </div>
        {/* List Image */}
        <div className={styles.listImageBox}>
          <div className={styles.listImage}>
            <div className={styles.listImage_threeImage}>
              <div className={styles.threeImageBox}>
                <WrapImage
                  desktop={{
                    src: '/images/unsplash_pfR18JNEMv8.png',
                    alt: 'Image',
                    layout: 'fill',
                    objectFit: 'cover',
                    className: `${styles.threeImage}`,
                  }}
                />
              </div>
              <div className={styles.threeImageBox}>
                <WrapImage
                  desktop={{
                    src: '/images/unsplash_9XngoIpxcEo.png',
                    alt: 'Image',
                    layout: 'fill',
                    objectFit: 'cover',
                    className: `${styles.threeImage}`,
                  }}
                />
              </div>
              <div className={styles.threeImageBox}>
                <WrapImage
                  desktop={{
                    src: '/images/unsplash_enGr5YbjQKQ.png',
                    alt: 'Image',
                    layout: 'fill',
                    objectFit: 'cover',
                    className: `${styles.threeImage}`,
                  }}
                />
              </div>
            </div>
            <div className={styles.listImage_twoImage}>
              <div className={styles.twoImageBox}>
                <WrapImage
                  desktop={{
                    src: '/images/unsplash_9Q_pLLP_jmA.png',
                    alt: 'Image',
                    layout: 'fill',
                    objectFit: 'cover',
                    className: `${styles.twoImage}`,
                  }}
                />
              </div>
              <div className={styles.twoImageBox}>
                <WrapImage
                  desktop={{
                    src: '/images/unsplash_VuwAfoHpxgs.png',
                    alt: 'Image',
                    layout: 'fill',
                    objectFit: 'cover',
                    className: `${styles.twoImage}`,
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.imageMobileBox}>
            <WrapImage
              desktop={{
                src: '/images/unsplash_pfR18JNEMv8Mobile.png',
                alt: 'Image',
                layout: 'fill',
                objectFit: 'cover',
                className: `${styles.imageMobile}`,
              }}
            />
          </div>
        </div>
        {/* Show reasons why users should join telio */}
        <div className={styles.reasonBox}>
          <h2 className={styles.reason_headding}>
            {T('tai-sao-lua-chon-telio')}
          </h2>
          <div className={styles.reasonList}>
            <Reason />
          </div>
        </div>
        <div className={styles.AboutUs}>
          <AboutUs />
        </div>
        <div className={styles.listJob} id='jobList' ref={jobRef}>
          <div className={styles.listJobBox}>
            <h1 className={styles.listJobHedding}>{T('co-hoi-viec-lam')}</h1>
            <DynamicSearchFeature
              filterSearch={filterSearch}
              loading={isLoading}
              setParamsFilter={setParamsFilter}
              paramsFilter={paramsFilter}
            />

            <div
              className={styles.showSearchListButton}
              onClick={() => setShowSearchListMobile(true)}
            >
              <WrapImage
                desktop={{
                  src: '/images/Shape.png',
                  alt: 'delete',
                  layout: 'fill',
                  objectFit: 'contain',
                  className: `${styles.listIcon}`,
                }}
              />
              <div className={styles.showSearchListButton_text}>
                {T('bo-loc-capitalize')}
              </div>
            </div>
            {/* Display the filter background of Moblie */}

            <SearchListMobile
              setShowSearchListMobile={setShowSearchListMobile}
              init_data={init_data}
              setParamsFilter={setParamsFilter}
              filterSearch={filterSearch}
              showSearchListMobile={showSearchListMobile}
            />

            {/* Display the filter background of PC */}
            <div className={styles.listJobBody}>
              <SearchList
                loading={isLoading}
                init_data={init_data}
                setParamsFilter={setParamsFilter}
                filterSearch={filterSearch}
                paramsFilter={paramsFilter}
              />
              <div className={styles.listJobResult}>
                <div className={styles.listJobResultBox}>
                  {listJob.length === 0 && !isLoading ? (
                    <div className={styles.noResult}>
                      {T('cong-viec-phu-hop')}
                    </div>
                  ) : null}
                  {(listJob || []).map((job: any, index: number) => (
                    <Job key={index} jobDetail={job} />
                  ))}
                </div>
                <div style={{ flex: 1 }}></div>
              </div>
            </div>
            {/* Share with linkedIn, Facebook or Google */}
            {width <= 768 ? <ShareFeature /> : <></>}
          </div>
        </div>
      </div>
      <DynamicFooterBanner />
      <DynamicFooter />
    </div>
  );
}

export default ListJobContainer;
