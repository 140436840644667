import styles from './styles.module.css';
import React from 'react';
import { WrapImage } from '../wrapImage';
import { T } from '../../hooks/translation';

export default function Reason() {
  const arrayReason = [
    {
      title: 't - talent',
      image: 'user.png',
    },
    {
      title: 'e - excellence',
      image: 'excellence.png',
    },
    {
      title: 'l - leading',
      image: 'leading.png',
    },
    {
      title: 'i - impact',
      image: 'impact.png',
    },
    {
      title: 'o - openness',
      image: 'openness.png',
    },
  ];

  return (
    <div className={styles.reasonDiv}>
      {arrayReason.map((reason, index) => {
        return (
          <div key={index} className={styles.reasonBox}>
            <div className={styles.reason}>
              <WrapImage
                desktop={{
                  src: `/images/${reason.image}`,
                  alt: 'image',
                  width: 142,
                  height: 118,
                  objectFit: 'contain',
                  className: `${styles.reason_image}`,
                }}
              />
              <h3 className={styles.reason_headding}>
                {reason.title}
                <br />
                <span>{T(`${reason.title}-title`)}</span>
              </h3>
              <p className={styles.reason_content}>
                {T(`${reason.title}-content`)}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
