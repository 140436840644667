import React from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import ListJobContainer from '../src/containers/listJob/listJob';
import { dehydrate, QueryClient, useQuery } from 'react-query';
import { createRequest } from '../src/utils/request';
import { configs } from '../src/configs';
import { useRouter } from 'next/router';
import { T } from '../src/hooks/translation';

const Home: NextPage = () => {
  const router = useRouter();
  const { dept, location, type, name } = router.query;
  const { data: rq_data, isLoading } = useQuery(
    ['getListJob', dept, location, type, name],
    () => getListJob(dept, location, type, name),
    { refetchOnWindowFocus: false }
  );
  const { data: init_data } = useQuery(['getInitJob'], () => getInitJob(), {
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Head>
        <title>{`Telio | ${T('page-title')}`}</title>
        <meta name='description' content='Telio | Tuyển dụng' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <link rel='icon' href='/icons/icon-48x48.png' />
        <meta property='og:title' content={`Telio | Tuyển dụng`} />
        <meta property='og:type' content='Job' />
        <meta
          property='og:image'
          content={`${configs.siteEndpoint}/images/bannerMobileBackground.png`}
        />
        <meta property='og:url' content='https://hiring.telio.vn' />
        <meta
          name='google-site-verification'
          content='g3yjwKP-M7dNyYSxyv_fO_9CbXbpvWq4HVssfPmv4tU'
        />
        <meta
          name='twitter:card'
          content={`${configs.siteEndpoint}/images/bannerMobileBackground.png`}
        />

        <meta property='og:description' content={`Telio | Tuyển dụng`} />
        <meta property='og:site_name' content='Telio Job' />
        <meta name='twitter:image:alt' content={`Telio | Job`} />
      </Head>

      <main>
        <ListJobContainer
          listJob={rq_data?.data?.listJob}
          init_data={init_data?.data}
          isLoading={isLoading}
        />
      </main>
    </>
  );
};
const getListJob = async (
  dept: string | number | string[] | undefined,
  location: string | number | string[] | undefined,
  type: string | number | string[] | undefined,
  name: any
) => {
  return createRequest(
    `${configs.apiJobEndpoint}/v1/jobs?${[
      dept ? 'dept=' + dept : '',
      location ? 'location=' + location : '',
      type ? 'type=' + type : '',
      name ? 'name=' + encodeURI(name) : '',
    ]
      .filter((e) => e != '')
      .join('&')}`,
    {
      method: 'GET',
    }
  );
};
const getInitJob = async () => {
  return createRequest(`${configs.apiJobEndpoint}/v1/jobs/init`, {
    method: 'GET',
  });
};
export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  const queryClient = new QueryClient();
  const dept = query.dept || '';
  const location = query.location || '';
  const type = query.type || '';
  const name = query.name || '';

  await queryClient.prefetchQuery(
    ['getListJob', dept, location, type, name],
    () => getListJob(dept, location, type, name)
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default Home;
